import React, { useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import useWindowHeight from "../components/useWindowHeight"
// import { motion } from "framer-motion"

//components
import Layout from "../components/layout"
import HeroSection from "../components/heroSection"
import Seo from "../components/seo"
import ServicesSectionVersion2 from "../components/servicesSectionVersion2"
import CtaSection from "../components/CtaSection"
// import FloatingCtaButton from "../components/floatingCtaButton"
import Loader from "../components/Loader"
import ProjectsSection from "../components/ProjectsSection"


const IndexPage = () => {

  const [loading, setLoading] = useState(true);
  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: '0px',
    threshold: '0',
});

  const height = useWindowHeight();
    
  useEffect( () => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);

  
  
  return (
  <>
    { loading ? ( 
      <Loader setLoading={setLoading} key='loader' height={ height } />
    ) : (  
    <Layout navBarType="gray" page="home" isBgDark={ true } loading={ loading }>
        <Seo title="Home" />
          <HeroSection viewportHeight={ height }/>  
          <ServicesSectionVersion2 />
          <ProjectsSection />
          <CtaSection />
    </Layout>
    )
  }
</>
)}

export default IndexPage
