import * as React from "react"
import { Link } from "gatsby";



const CtaSection = () => {
    
    return (
    <section className="w-full bg-neutral-50 flex align-center justify-center" style={{ height: 700 }}>
            <div className="flex flex-col align-center justify-center text-center">
                <h3 className="m-8 text-3xl sm:text-5xl">
                    Ready to get started?
                </h3>
                <div className="m-8">
                    <p className="text-xl">
                        Schedule a free 30 min info call with us.
                    </p>
                </div>
                <div className="m-8">
                    {/* <Link to="/schedule" className="bg-gradient-to-r from-cyan-200 to-cyan-300 hover:to-cyan-200 rounded-full text-black px-8 py-4 shadow-xl text-xl">
                    Schedule a call
                    </Link> */}
                    <Link to="/schedule" className=" bg-gradient-to-r from-teal-400 to-cyan-600 hover:to-cyan-400 text-white rounded-full px-8 py-4 shadow-xl text-xl">
                    Schedule a call
                    </Link>
                </div>
            </div>
    </section>
)}

export default CtaSection

