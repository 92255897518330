import React from "react";
import { motion } from "framer-motion"

// components
import HeroForPage from "./heroForPage"

// images

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] }

const shopUxLogo = {
  animate: {
    y: 0, 
    transition: {
      delayChildren: .6, 
      staggerChildren: .04, 
      staggerDirection: -1,
    }
  }
}

const oddLetter = {
  initial: {
    opacity:0,
    y: 400
  },
  animate: {
    opacity:1,
    y: 0,
    transition: { duration: 1, ...transition}
  }
}

const evenLetter = {
  initial: {
    opacity:0,
    y: -400
  },
  animate: {
    opacity:1,
    y: 0,
    transition: { duration: 1, ...transition}
  },
  exit: {
    opacity:0
  }
}


const Loader = ({ setLoading, height }) => { 
  return (
    <div className='loader bg-neutral-900'>
      <motion.div 
        initial='initial'
        animate='animate'
        exit='exit'
        onAnimationComplete={() => setLoading( false )}>
          <HeroForPage viewportHeight={ height }> 
            <h2  
              className="w-full text-6xl sm:text-9xl text-center"
              style={{
                position: "absolute",
                top: "40%",
                transform: "translateY(-50%)"
              }}>
              <motion.span variants={ shopUxLogo } className="inline-block text-white">
                <motion.span variants={ oddLetter }  className="inline-block">S</motion.span>
                <motion.span variants={ evenLetter }  className="inline-block">h</motion.span>
                <motion.span variants={ oddLetter }  className="inline-block">o</motion.span>
                <motion.span variants={ evenLetter }  className="inline-block">p</motion.span>
                <motion.span variants={ oddLetter }  className="inline-block">U</motion.span>
                <motion.span variants={ evenLetter }  className="inline-block">X</motion.span>
              </motion.span>
            </h2>
          </HeroForPage>
      </motion.div>
    </div>
  );
};

// export const ImageBlock = ({ id }) => {
//   return (
//     <div className={`image-block ${id}`}>
      
//       <Image
//         src={process.env.PUBLIC_URL + `/images/${id}.webp`}
//         fallback={process.env.PUBLIC_URL + `/images/${id}.jpg`}
//         alt={id}
//       />
//     </div>
//   );
// };
export default Loader;
