import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Project({ project }) {

    const { projectSectionImage, logoWhite, title, slug } = project.frontmatter

    return (
        <div className="grid grid-cols-2 md:grid-cols-6 gap-8 2xl:mx-28 py-8">
            <GatsbyImage image={ projectSectionImage.childImageSharp.gatsbyImageData } className="col-span-full rounded-xl overflow-hidden" />
            <img src={ logoWhite.publicURL } className="col-span-full md:col-span-3 w-40 row-span-3" alt={`logo for ${ title }`} />
            {/* <p  className="col-span-full md:col-start-3 md:col-end-7 text-neutral-300">Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium sint, error quis illum commodi minus quasi ullam et magni eos laudantium eligendi iusto fugiat itaque eum cupiditate asperiores voluptatibus voluptatum odit aspernatur? Nemo dolor exercitationem ipsa alias eveniet? At suscipit minima ipsum deleniti earum! Laboriosam ducimus iusto amet dolorum? Ipsum.</p> */}
            <div className="col-span-full md:col-start-4 md:col-end-7 md:text-right">
                <Link to={ "/projects/" + slug } className="text-neutral-50 pr-6"><span className="hover:underline">Project details</span><FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-3"/></Link>
                <Link to="/contact" className="text-neutral-50"><span className="hover:underline">Get started</span><FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-3"/></Link>
            </div>
        </div> 
    )
}

function NavIcon(props) {
    // `isSelected` comes from `TabList` cloning the `CoolTab`.
    const { isSelected } = props;

    // make sure to forward *all* props received from TabList
    return (
        <Tab className="" {...props}>
            <button className={`bg-neutral-900 active:relative active:top-px active:left-px cursor-pointer rounded-full h-5 w-5 m-2 outline-0 flex items-center justify-center focus-visible:bg-neutral-300`} aria-current={isSelected}>
                <span className={`${isSelected ? 'bg-gradient-to-r from-teal-400 to-cyan-600 hover:to-cyan-400' : 'bg-neutral-100'} inline-block rounded-full h-5 w-5`}/>
            </button>
        </Tab>
    );
  }


function ProjectsDisplay() {
    const carousel = useStaticQuery(graphql`
    query tabsCarouselData {
        projects: allMarkdownRemark(
            filter: {frontmatter: {type: {eq: "project"}}}
            sort: {fields: frontmatter___date, order: DESC}
        ) {
            nodes {
            frontmatter {
                services
                slug
                showSection
                title
                projectPageHeadline
                logo {
                    publicURL
                }
                projectSectionImage {
                childImageSharp {
                    gatsbyImageData
                }
                }
                logoWhite {
                    publicURL
                }
            }
            }
        }
    }
`) 

    const projects = carousel.projects.nodes

    return ( 
        <Tabs>
            <TabPanels> 
            { projects.map( ( project, idx ) => (
                <TabPanel key={ idx }> 
                    <Project project={ project } />
                </TabPanel>
            )) }
            </TabPanels>  
            <TabList className="flex justify-center">
                { projects.map( ( project, idx ) => ( 
                    <NavIcon key={ idx }/>
                    ))}
            </TabList>
        </Tabs>
    );
}

const ProjectsSection = () => {

  const copyData = {
    headline: "Built for growth",
    category: "our work"
  }
  
  return (
    <section className="w-full bg-neutral-900">

        <div className="container mx-auto py-24">
            <div className="flex items-center flex-col text-white text-center">
                <p className="text-lg max-w-xl mb-8 uppercase">{ copyData.category }</p>
                <h2 className="text-5xl lg:text-6xl mb-8">{ copyData.headline }</h2>
            </div>
            {/* <h2 className="text-8xl text-white w-full md:text-center tracking-wide pb-12" style={{lineHeight: '1.1'}}>{ copyData.title }</h2> */}
            <ProjectsDisplay />
        </div>
    </section>
  )
}

export default ProjectsSection