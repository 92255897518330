import React from "react"
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GatsbyImage } from "gatsby-plugin-image"


function Service({ heading, heading2, category, text, images, id, cta, imageRight, link }){

    let featuredImage
  
    images.forEach( item => {
            if(item.node.name === id) {
                featuredImage = item.node.childImageSharp.gatsbyImageData
            }
        }
    )

    return (
    <Link to={ link } className="hover:cursor-pointer">
        <div className="mb-2 bg-white lg:mb-12 xl:mb-24">
        <div 
          className="flex flex-wrap items-center justify-between container mx-auto"
          style={{
              flexDirection: imageRight ? "row-reverse" : "row"
            }}
        >
            {/* <div className="py-8 md:w-3/5 lg:mb-0 sm:px-4 lg:px-6 lg:px-16 xl:px-24"> */}
            <div className="flex flex-col w-full lg:w-1/2 xl:w-2/5 pt-8 lg:px-4 xl:px-6">            
                <h2 className="text-xs uppercase mb-4 tracking-wider text-neutral-500">
                    { category }
                </h2>
                <div className="text-3xl lg:text-4xl mb-6 font-bold font-display">
                    <h3>
                      { heading }
                    </h3>
                    <h3>
                      { heading2 }
                    </h3>
                </div>
                <p className="text-base lg:text-lg text-neutral-700 mb-6">
                    { text }
                </p>
                <div className="flex space-between">
                    <Link to="/contact" className="text-lg text-cyan-600  mb-6 hover:underline">
                        { cta }<FontAwesomeIcon icon={ faChevronRight } className="ml-2 h-4"/>
                    </Link>
                </div>
            </div>
            <div className="lg:w-1/2 xl:w-3/5 flex align-center justify-center" >
                <GatsbyImage image={ featuredImage } alt={`Image for ${category}`} />
            </div>
        </div>
        </div>
    </Link>
  )
}


export default Service