import React from "react"
import useWindowHeight from "./useWindowHeight"

const HeroForPage = ({ children }) => {

    const height = useWindowHeight();

    return (
    <section className="w-full relative text-center" style={{ height: height}}>
    
        { children }
   
    </section>
    )
}

export default HeroForPage