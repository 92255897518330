import React from "react"
import TextLoop from "react-text-loop"
import CtaButtonLg from "./ctaButtonLg"

import { motion } from "framer-motion"

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9]}

const HeroSection = ({ viewportHeight }) => {

    return (
    <section className="w-full z-20 bg-neutral-900 relative">
        <div className="container mx-auto flex flex-col xl:flex-row items-start xl:items-center justify-center intro-message-section" style={{ height: viewportHeight }}>            
            
            <motion.h2 
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1, transition: transition }}
            className="xl:w-3/4 font-display">
                <div className="text-6xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-8xl text-white">
                    {"We are Shopify "}
                </div>
                <div className="text-6xl sm:text-6xl md:text-7xl lg:text-8xl 2xl:text-8xl mb-12 text-white">
                    <TextLoop>
                        <span className="text-cyan-400">development</span>
                        <span className="text-cyan-400">design</span>
                        <span className="text-cyan-400">migration</span>
                        <span className="text-cyan-400">optimization</span>
                        <span className="text-cyan-400">marketing</span> 
                    </TextLoop>
                    {" experts."}
                </div>
                
            </motion.h2>

            <div className="xl:w-1/4">
                <motion.h3
                    initial = {{ opacity: 0 }}
                    animate = {{ opacity: 1, transition: { delay: .4, ...transition }}}
                    className="text-xl max-w-4xl text-white mb-12 text-neutral-200"
                    >
                    We bring your vision to life with designs that help you reach your target customers and inspire action.  
                </motion.h3>
                <motion.div 
                    initial= {{ opacity: 0 }}
                    animate = {{ opacity: 1, transition: { delay: .6, ...transition }}}
                    className="self-end xl:self-start"
                    >
                    <CtaButtonLg message="Let's talk" link="/contact" />
                </motion.div>
            </div>   
            {/* <ScrollDownBottom /> */}
        </div>
    </section>
    )
}

export default HeroSection